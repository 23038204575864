<script setup lang="ts">
import type { NuxtError } from '#app'

const { error = null } = defineProps({
  error: Object as () => NuxtError,
})

console.log(error)

const handleError = () => clearError({ redirect: '/home' })
</script>

<template>
  <div>
    <NuxtLayout>
      <section class="space-y-6">
        <template v-if="error?.statusCode === 404">
          <NuxtImg class="w-16" src="/img/404.svg" alt="Zeno FM logo" />
          <h1 class="lg:text-8xl md:text-7xl text-6xl font-monigue uppercase">404 Nothing here</h1>

          <p class="text-lg">Sorry, the page you are looking for could not be found.</p>
        </template>

        <template v-else>
          <NuxtImg class="w-16" src="/img/5xx.svg" alt="Zeno FM logo" />
          <h1 class="lg:text-8xl md:text-7xl text-6xl font-monigue uppercase">
            Ooops...<br />
            Something broke
          </h1>

          <p class="text-lg">
            Please try to refresh this page, or
            <a class="underline" href="mailto:support@zenomedia.com">contact our support team</a> if the problem
            persists.
          </p>
        </template>

        <div>
          <Button class="gap-2.5 rounded-full" @click="handleError">
            <Icon size="19" name="solar:arrow-left-linear" />
            Back to Home
          </Button>
        </div>
      </section>
    </NuxtLayout>
  </div>
</template>
