
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93ILszah2VNpMeta } from "/app/pages/[...slug].vue?macro=true";
import { default as exploreRKECn8OWt4Meta } from "/app/pages/explore.vue?macro=true";
import { default as homeH3NsyxtJwrMeta } from "/app/pages/home.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91episodeId_93htGYsFVIMxMeta } from "/app/pages/podcast/[podcastId]/episodes/[episodeId].vue?macro=true";
import { default as indexeA43uBvsFiMeta } from "/app/pages/podcast/[podcastId]/index.vue?macro=true";
import { default as _91category_93iIyqkpHD22Meta } from "/app/pages/podcasts/categories/[category].vue?macro=true";
import { default as indexKRfllISBngMeta } from "/app/pages/podcasts/categories/index.vue?macro=true";
import { default as _91country_932fnZf4Dur2Meta } from "/app/pages/podcasts/countries/[country].vue?macro=true";
import { default as index6DlRyWOd6eMeta } from "/app/pages/podcasts/countries/index.vue?macro=true";
import { default as indexEHDN5ovAzVMeta } from "/app/pages/podcasts/index.vue?macro=true";
import { default as _91language_93L6QyKaUllsMeta } from "/app/pages/podcasts/languages/[language].vue?macro=true";
import { default as indexnchnFpgdT2Meta } from "/app/pages/podcasts/languages/index.vue?macro=true";
import { default as podcastst13v3mOxj1Meta } from "/app/pages/podcasts.vue?macro=true";
import { default as _91id_937iz5BwUU8oMeta } from "/app/pages/radio/[id].vue?macro=true";
import { default as _91country_93j8L0YUxLtzMeta } from "/app/pages/radio/countries/[country].vue?macro=true";
import { default as indexYUtMzTITxPMeta } from "/app/pages/radio/countries/index.vue?macro=true";
import { default as _91genre_935mia9jSaOfMeta } from "/app/pages/radio/genres/[genre].vue?macro=true";
import { default as indexlEt0JCeG0bMeta } from "/app/pages/radio/genres/index.vue?macro=true";
import { default as indexeV3TMC4AIXMeta } from "/app/pages/radio/index.vue?macro=true";
import { default as _91language_93Hpc7YcLMMpMeta } from "/app/pages/radio/languages/[language].vue?macro=true";
import { default as indexVgGj62xDJOMeta } from "/app/pages/radio/languages/index.vue?macro=true";
import { default as radio5SQMjpPEHnMeta } from "/app/pages/radio.vue?macro=true";
import { default as _91_46_46_46slug_93iB0OIrxFpcMeta } from "/app/pages/show/[...slug].vue?macro=true";
import { default as component_45stubnO9MU04yTUMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubnO9MU04yTU } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/app/pages/[...slug].vue")
  },
  {
    name: "explore",
    path: "/explore",
    component: () => import("/app/pages/explore.vue")
  },
  {
    name: "home",
    path: "/home",
    component: () => import("/app/pages/home.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "podcast-podcastId-episodes-episodeId",
    path: "/podcast/:podcastId()/episodes/:episodeId()",
    component: () => import("/app/pages/podcast/[podcastId]/episodes/[episodeId].vue")
  },
  {
    name: "podcast-podcastId",
    path: "/podcast/:podcastId()",
    component: () => import("/app/pages/podcast/[podcastId]/index.vue")
  },
  {
    name: podcastst13v3mOxj1Meta?.name,
    path: "/podcasts",
    component: () => import("/app/pages/podcasts.vue"),
    children: [
  {
    name: "podcasts-categories-category",
    path: "categories/:category()",
    component: () => import("/app/pages/podcasts/categories/[category].vue")
  },
  {
    name: "podcasts-categories",
    path: "categories",
    component: () => import("/app/pages/podcasts/categories/index.vue")
  },
  {
    name: "podcasts-countries-country",
    path: "countries/:country()",
    component: () => import("/app/pages/podcasts/countries/[country].vue")
  },
  {
    name: "podcasts-countries",
    path: "countries",
    component: () => import("/app/pages/podcasts/countries/index.vue")
  },
  {
    name: "podcasts",
    path: "",
    component: () => import("/app/pages/podcasts/index.vue")
  },
  {
    name: "podcasts-languages-language",
    path: "languages/:language()",
    component: () => import("/app/pages/podcasts/languages/[language].vue")
  },
  {
    name: "podcasts-languages",
    path: "languages",
    component: () => import("/app/pages/podcasts/languages/index.vue")
  }
]
  },
  {
    name: radio5SQMjpPEHnMeta?.name,
    path: "/radio",
    component: () => import("/app/pages/radio.vue"),
    children: [
  {
    name: "radio-id",
    path: ":id()",
    component: () => import("/app/pages/radio/[id].vue")
  },
  {
    name: "radio-countries-country",
    path: "countries/:country()",
    component: () => import("/app/pages/radio/countries/[country].vue")
  },
  {
    name: "radio-countries",
    path: "countries",
    component: () => import("/app/pages/radio/countries/index.vue")
  },
  {
    name: "radio-genres-genre",
    path: "genres/:genre()",
    component: () => import("/app/pages/radio/genres/[genre].vue")
  },
  {
    name: "radio-genres",
    path: "genres",
    component: () => import("/app/pages/radio/genres/index.vue")
  },
  {
    name: "radio",
    path: "",
    component: () => import("/app/pages/radio/index.vue")
  },
  {
    name: "radio-languages-language",
    path: "languages/:language()",
    component: () => import("/app/pages/radio/languages/[language].vue")
  },
  {
    name: "radio-languages",
    path: "languages",
    component: () => import("/app/pages/radio/languages/index.vue")
  }
]
  },
  {
    name: "show-slug",
    path: "/show/:slug(.*)*",
    component: () => import("/app/pages/show/[...slug].vue")
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/search",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/login/",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/signup/",
    component: component_45stubnO9MU04yTU
  }
]