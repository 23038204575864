export const zenoPlayClient = async <T>(endpoint: string, options: NitroFetchOptions = {}): Promise<T> => {
  const config = useRuntimeConfig()
  const baseURL = config.public.apiZenoPlayUrl

  return await $fetch(`${baseURL}/api/zenofm/${endpoint}`, options)
}

export const toolsClient = async <T>(endpoint: string, options: NitroFetchOptions = {}): Promise<T> => {
  const config = useRuntimeConfig()
  const baseURL = config.public.toolsServiceUrl

  return await $fetch(`${baseURL}/${endpoint}`, options)
}

export const audioAdClient = async <T>(endpoint: string, options: NitroFetchOptions = {}): Promise<T> => {
  const config = useRuntimeConfig()
  const baseURL = config.public.audioAdBaseUrl

  return await $fetch(`${baseURL}/${endpoint}`, options)
}
