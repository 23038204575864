<script setup lang="ts">
import { useCookieConsentStore } from '~/stores/useCookieConsentStore'
import { usePlayerStore } from '~/stores/usePlayerStore'

const ccStore = useCookieConsentStore()
const playerStore = usePlayerStore()

useScriptGoogleAnalytics({
  id: 'G-BPTE0F3T9Q',
})

onMounted(() => {
  ccStore.fetchGDPR()
})

watch(
  () => ccStore.consented,
  () => {
    let consent = ''

    if (ccStore.isConsentRequired) {
      consent = `?aw_0_req.gdpr=${ccStore.isTargetingAccepted}`
    }

    useScript('https://synchrobox.adswizz.com/register2.php' + consent)
    useScript('https://cdn.adswizz.com/adswizz/js/SynchroClient2.js' + consent)
    useScript('https://playerservices.live.streamtheworld.com/api/idsync.js?stationId=118143&bounce=true')
    useScript('https://play.adtonos.com/attc-uAdJ2ujapxW6xDFKk.min.js')
  }
)
</script>

<template>
  <div>
    <NuxtLoadingIndicator color="#8028F8" />
    <NuxtRouteAnnouncer />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>

    <div v-if="playerStore.media" class="z-20 fixed w-full bottom-0 left-0 drop-shadow-[0_0_50px_rgba(0,0,0,0.25)]">
      <LazyPlayerCompact v-if="$viewport.isLessThan('md')" />
      <LazyPlayer v-else />
    </div>

    <LazyCookieConsent v-if="ccStore.isConsentRequired" />
    <LazyPlayerInput />
    <LazyToaster />
  </div>
</template>
